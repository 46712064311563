import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";

import { useSelector, useDispatch } from "react-redux";
import { currentUser, getCurrentUser } from "../slices/authSlice";

const Setting = () => {
  const dispatch = useDispatch();
  const currUser = useSelector(currentUser);
  const userStatus = useSelector((state) => state.auth.status);

  const [activeTab, setActiveTab] = useState("profile");
  const [user, setUser] = useState(false);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (userStatus == "succeeded") {
      setUser(currUser.user);
    }
  }, [userStatus]);

  console.log("user: ", user);

  return (
    <main className="flex setting-page">
      <Sidebar title="Setting" />
      <div className="main-container">
        <Header />
        {/* main content */}
        <div className="dashboard">
          <div className="tabs flex">
            <div className="tab_links">
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "profile" ? "active" : ""
                }`}
                onClick={() => handleTabClick("profile")}
                data-tab="profile"
              >
                PROFILE
              </a>
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "account" ? "active" : ""
                }`}
                onClick={() => handleTabClick("account")}
                data-tab="account"
              >
                ACCOUNT
              </a>
              <a
                href="#"
                className={`tab_link ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
                onClick={() => handleTabClick("feedback_complaint")}
                data-tab="feedback_complaint"
              >
                FEEDBACK & COMPLAINTS
              </a>
              <a href="#" className="btn dashboard__btn">
                UPGRADE
              </a>
            </div>
            <div className="tabs_content">
              <div
                className={`profile ${activeTab === "profile" ? "active" : ""}`}
              >
                {/* remove profile: user request 2025/01/07 */}
                {/* <div className="user_img">
                  <img
                    src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1505"
                    alt="user"
                  />
                </div>
                <a href="#" className="btn dashboard__btn inline-block">
                  Change Profile
                </a> */}
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Full Name"
                      value={user ? user.displayname : "-"}
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Designation"
                      value={user ? user.position : "-"}
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Email"
                      value={user ? user.username : "-"}
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      name="whatsappnumberid"
                      id="whatsappnumberid"
                      placeholder="Whatsapp Business ID"
                      value={user ? user.whatsappnumberid : "-"}
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Company Name"
                      value={user ? user._enterprise : "-"}
                      readOnly
                    />
                    <button className="edit">
                      <img src="assets/icons/icon-edit.svg" alt="edit" />
                    </button>
                  </div>

                  <div className="input-group">
                    <button className="btn dashboard__btn inline-block">
                      Submit
                    </button>
                    <button className="btn dashboard__btn_white inline-block">
                      {" "}
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
              <div
                className={`account ${activeTab === "account" ? "active" : ""}`}
              >
                <form action="#" className="setting_form">
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Display Name"
                      value={user ? user.displayname : "-"}
                    />
                    <img src="assets/icons/icon-edit.svg" alt="edit" />
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Phone"
                      value={user ? user.phone : "-"}
                    />
                    <img src="assets/icons/icon-edit.svg" alt="edit" />
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Email"
                      value={user ? user.username : "-"}
                    />
                    <img src="assets/icons/icon-edit.svg" alt="edit" />
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="English"
                      value="National_ID.pdf"
                    />
                  </div>

                  <div className="input-group">
                    {/* <input type="text" placeholder="Language" /> */}
                    <select>
                      <option>Language</option>
                      <option>English</option>
                      <option>French</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="**********"
                      value="**********"
                    />
                    <img src="assets/icons/icon-edit.svg" alt="edit" />
                  </div>

                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Submit
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
              <div
                className={`feedback_complaint ${
                  activeTab === "feedback_complaint" ? "active" : ""
                }`}
              >
                <form action="#" className="setting_form">
                  <textarea
                    name="feedback_complaint"
                    id="feedback_complaint"
                    defaultValue={""}
                  />

                  <div className="flex setting-checkboxes">
                    <div>
                      <input type="radio" id="feedback" name="reason" />
                      <label htmlFor="feedback">Feedback</label>
                    </div>

                    <div>
                      <input type="radio" id="feedback" name="reason" />
                      <label htmlFor="feedback">Complaint</label>
                    </div>
                  </div>
                  <div className="input-group">
                    <a href="#" className="btn dashboard__btn inline-block">
                      Submit
                    </a>
                    <a
                      href="#"
                      className="btn dashboard__btn_white inline-block"
                    >
                      Cancel
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Setting;
