import { useEffect, useState } from "react";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import TodoList from "../components/TodoList";

// redux state
import { useSelector, useDispatch } from "react-redux";
import { currentCalendar, getCalendar } from "../slices/calendarSlice";

import loader from "../loading.svg";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

const myEventsList = [
  // {
  //   title: "Monkey Business",
  //   start: new Date("2024-05-27T21:00:00+03:00"), // April 9th, 2024 at 10:00 AM
  //   end: new Date("2024-05-27T21:30:00+03:00"), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Further Monkey Business",
  //   start: new Date(2024, 3, 9, 9, 30), // April 9th, 2024 at 10:00 AM
  //   end: new Date(2024, 3, 9, 10, 0), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Meeting",
  //   start: new Date(2024, 3, 9, 10, 0), // April 9th, 2024 at 10:00 AM
  //   end: new Date(2024, 3, 9, 11, 0), // April 9th, 2024 at 11:00 AM
  // },
  // {
  //   title: "Doctor's Appointment",
  //   start: new Date(2024, 3, 12, 14, 30), // April 12th, 2024 at 2:30 PM
  //   end: new Date(2024, 3, 12, 15, 30), // April 12th, 2024 at 3:30 PM
  // },
  // {
  //   title: "Birthday Party",
  //   start: new Date(2024, 3, 16, 19, 0), // April 16th, 2024 at 7:00 PM
  //   end: new Date(2024, 3, 17, 1, 0), // April 17th, 2024 at 1:00 AM (next day)
  // },
];

const Calender = () => {
  const dispatch = useDispatch();
  const calendar = useSelector(currentCalendar);
  const calendarStatus = useSelector((state) => state.calendar.status);

  const [events, setEvents] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [smallPopup, setSmallPopup] = useState(false);
  const [isLoading, setIsLoading] = useState("block");
  const [popupText, setPopupText] = useState("");
  const [slotData, setSlotData] = useState("");

  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [timeStart, setTimeStart] = useState("");
  const [duration, setDuration] = useState("30mins");
  const [description, setDescription] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isGoogleAccessToken, setIsGoogleAccessToken] = useState(false);

  // put calendar events in state once successfully fetched
  useEffect(() => {
    if (calendarStatus === "succeeded" && typeof calendar.events != "string") {
      const newEvents = calendar.events.map((element) => ({
        title: element.summary,
        start: new Date(element.start.dateTime),
        end: new Date(element.end.dateTime),
        details: element,
      }));
      setEvents(newEvents);
    }
  }, [calendarStatus, dispatch]);

  // useEffect(() => {
  //   const checkGoogleAuth = async () => {
  //     try {
  //       const response = await axios.get("auth/status", {
  //         withCredentials: true,
  //       });
  //       console.error("isAuthenticated: ", response.data.isAuthenticated);
  //       console.error("googleAccessToken: ", response.data.isGoogleAccessToken);
  //       setIsAuthenticated(response.data.isAuthenticated);
  //       setIsGoogleAccessToken(response.data.isGoogleAccessToken);

  //       if (
  //         response.data.isAuthenticated &&
  //         !response.data.isGoogleAccessToken
  //       ) {
  //         console.error(
  //           "Google Access Token Not Found. Execute Google Authentication..."
  //         );
  //         window.open("http://localhost:5000/auth/google", "_self");
  //         // window.open("https://agent.altctrl.ai/auth/google", "_self");
  //       }
  //     } catch (error) {
  //       console.error("Error checking Google OAuth status:", error);
  //     }
  //   };

  //   checkGoogleAuth();
  // }, []);

  console.log("myEventsList: ", myEventsList);
  console.log("events: ", events);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSmallPopup(true);
    let eventStart = timeStart.toString();
    let eventEnd = "";
    if (duration == "30mins") {
      let originalDate = new Date(timeStart);
      originalDate.setMinutes(originalDate.getMinutes() + 30);
      eventEnd = originalDate.toString();
    } else {
      let originalDate = new Date(timeStart);
      originalDate.setHours(originalDate.getHours() + 1);
      eventEnd = originalDate.toString();
    }
    let data = {
      title: title,
      name: name,
      email: email,
      start: eventStart,
      end: eventEnd,
      description: description,
    };
    console.log("event data:", data);
    try {
      const response = await axios.post("api/agent/create-event", data, {
        withCredentials: true,
      });
      console.log("success: ", response);
      setIsLoading("none");
      setPopupText("Success");
      dispatch(getCalendar());
      setTimeout(() => {
        setSmallPopup(false);
        setIsPopup(false);
      }, 3000);
    } catch (error) {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data.redirect
      ) {
        // Redirect the user to the Google OAuth authentication process
        window.location.href = error.response.data.redirect;
      } else {
        // Handle other errors
        console.error("Error creating event:", error);
        console.log("error: ", error);
        setPopupText("Error");
        setIsLoading("none");
        setTimeout(() => {
          setSmallPopup(false);
          setIsPopup(false);
        }, 3000);
      }
    }
  };

  const handleGoogleAuth = () => {
    // window.open("http://localhost:5000/auth/google", "_self");
    window.open("https://agent.altctrl.ai/auth/google", "_self");
  };

  return (
    <div className="flex">
      <Sidebar title="Calender" />
      <div className="main-container" style={{ padding: "20px" }}>
        <Header />
        <div className="flex">
          <div style={{ width: "100%" }}>
            <div className="flex">
              <div className="event_catagory flex">
                <div className="flex">
                  <p className="evnt_meetings">
                    <span></span> Meetings
                  </p>
                  <p className="evnt_viewing">
                    <span></span> Viewing
                  </p>
                  <p className="evnt_calls">
                    <span></span> Calls
                  </p>
                </div>
                <div className="flex">
                  <a href="#" onClick={handleGoogleAuth}>
                    Google Auth
                  </a>
                  <a href="#">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5.25L12 12.1443M12 12.1443L5.10571 12.1443M12 12.1443L12.0001 19.0386M12 12.1443L18.8943 12.1443"
                        stroke="#F4F4F4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add Event
                  </a>
                  <a href="#">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5.25L12 12.1443M12 12.1443L5.10571 12.1443M12 12.1443L12.0001 19.0386M12 12.1443L18.8943 12.1443"
                        stroke="#F4F4F4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Add task
                  </a>
                  <a href="#">Export to Calendar</a>
                </div>
              </div>
            </div>
            <DnDCalendar
              selectable={true}
              onSelectEvent={(data) => {
                console.log("onSelectEvent", data);
              }}
              onSelectSlot={(slot) => {
                console.log("onSelectSlot", slot);
                setSlotData(slot);
                setIsPopup(true);
                setTimeStart(slot.start);
              }}
              onSelecting={(day) => {
                console.log("onSelect", day);
              }}
              localizer={localizer}
              events={events}
              startAccessor="start"
              draggableAccessor={(event) => true}
              endAccessor="end"
              style={{ height: 500 }}
            />

            {isPopup && (
              <div className="leads-popup leads-popup--delete add-event-container">
                <div className="leads-popup__container flex justify-content-center align-center add-event-popup">
                  <h3 style={{ paddingBottom: "20px" }}>
                    Schedule meeting
                    <br />
                    on {new Date(slotData.start).toDateString()} at{" "}
                    {new Date(slotData.start).toLocaleTimeString()}
                  </h3>
                  <form
                    onSubmit={handleSubmit}
                    className="flex justify-content-center align-center add-event-popup"
                  >
                    <input
                      required
                      type="text"
                      placeholder="Add Title"
                      className="mb-20"
                      name="title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <input
                      required
                      type="text"
                      placeholder="Your name"
                      className="mb-20"
                      name="title"
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      required
                      type="email"
                      placeholder="Your email"
                      className="mb-20"
                      name="title"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <select
                      required
                      name="list"
                      placeholder="Duration"
                      className="mb-20"
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="30mins">30mins</option>
                      <option value="1hr">1hr</option>
                    </select>
                    <textarea
                      required
                      placeholder="Add details"
                      defaultValue={""}
                      maxLength="1024"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <div className="flex align-center broad-btns">
                      <button
                        type="submit"
                        className="btn btn--schedule flex align-center"
                        onClick={(e) => {
                          setIsPopup(false);
                          setSlotData("");
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn--lunch">
                        Save Event
                      </button>
                    </div>
                  </form>
                </div>
                {smallPopup && (
                  <div className="leads-popup leads-popup--delete">
                    <div className="leads-popup__container flex justify-content-center align-center">
                      <img
                        src={loader}
                        style={{ height: "150px", display: isLoading }}
                      />
                      <h3
                        style={{
                          color: popupText == "Success" ? "green" : "red",
                        }}
                      >
                        {popupText}
                      </h3>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={{ width: "30%", marginLeft: "20px" }}>
            <TodoList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calender;
